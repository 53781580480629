<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode === false ? $t('message.addForwarderCost') : $t('message.updateForwarderCost') + ": " + productName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                </v-overlay>
                <v-form lazy-validation ref="forwarderCostForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.productGroup') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allProductGroups"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="ProductGroup.title"
                                item-value="ProductGroup.id"
                                solo
                                v-model="ForwarderCost__productgroup_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.warehouse') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="filteredOffices"
                                :rules="[...validationRules.required]"
                                autocomplete="off"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Office.title"
                                item-value="Office.id"
                                solo
                                v-model="ForwarderCost__office_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="types"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="ForwarderCost__type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.unit') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="uofmQty"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="Measurement.id"
                                item-text="Measurement.title"
                                solo
                                v-model="ForwarderCost__measurement_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.cost') + ' $' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                :value="ForwarderCost__cost"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="ForwarderCost__cost = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.add"
                    color="info"
                    small
                    @click="addForwarderCost()"
                    v-if="updateMode === false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveForwarderCost()"
                    v-if="updateMode === true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "../../api";
import {validationRules} from "@/store/modules/appic/constants";

export default {
    name: "AddForwarderCost",
    props: ['forwarderCostId','dialog','updateMode','productName'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                cost: v => !!v || this.$t('message.required'),
                product: v => !!v || this.$t('message.required'),
                warehouse: v => !!v || this.$t('message.required'),
            },
            validForm: true,
            ForwarderCost__id: null,
            ForwarderCost__office_id: 1,
            ForwarderCost__productgroup_id: null,
            ForwarderCost__cost: 0,
            ForwarderCost__type: null,
            ForwarderCost__measurement_id: 1,
            types: [
                {value: 'HW', text: 'Hardwood'},
                {value: 'SW', text: 'Softwood'},
            ]
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters([
            'offices',
            'uofmQty',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        filteredOffices() {
            return this.offices.filter(o => {
                return [1,21].includes(o.Office.id)
            })
        }
    },
    methods: {
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        addForwarderCost() {
            if(this.$refs.forwarderCostForm.validate()) {
                this.loading.add = true
                const warehouseVar = {
                    office_id: this.ForwarderCost__office_id,
                    productgroup_id: this.ForwarderCost__productgroup_id,
                    cost: this.ForwarderCost__cost,
                    type: this.ForwarderCost__type,
                    measurement_id: this.ForwarderCost__measurement_id
                }
                api
                    .post('/warehouse-vars/forwarder-cost', {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status === 'success') {
                            this.$toast.success(this.$t('message.successes.forwarderCostAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.forwarderCostNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.forwarderCostNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.forwarderCostNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        },
        getForwarderCostToUpdateById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/forwarder-cost/' + this.forwarderCostId)
                    .then((response) => {
                        const status = response.data.status
                        if(status === 'success'){
                            const data = response.data.data[0]
                            this.ForwarderCost__id = data.ForwarderCost.id
                            this.ForwarderCost__cost = data.ForwarderCost.cost
                            this.ForwarderCost__productgroup_id = data.ForwarderCost.productgroup_id
                            this.ForwarderCost__office_id = data.ForwarderCost.office_id
                            this.ForwarderCost__type = data.ForwarderCost.type
                            this.ForwarderCost__measurement_id = data.ForwarderCost.measurement_id
                            resolve('done')
                        } else {
                            reject('error')
                        }
                    })
                    .catch((err) => reject(err))
            })
        },
        resetForm() {
            this.ForwarderCost__productgroup_id = null
            this.ForwarderCost__office_id = 1
            this.ForwarderCost__cost = 0
            this.ForwarderCost__id = null
            this.ForwarderCost__type = null
            this.ForwarderCost__measurement_id = 1
        },
        saveForwarderCost() {
            if(this.$refs.forwarderCostForm.validate()) {
                this.loading.save = true
                const warehouseVar = {
                    office_id: this.ForwarderCost__office_id,
                    productgroup_id: this.ForwarderCost__productgroup_id,
                    cost: this.ForwarderCost__cost,
                    type: this.ForwarderCost__type,
                    measurement_id: this.ForwarderCost__measurement_id
                }
                api
                    .put('/warehouse-vars/forwarder-cost/' + this.forwarderCostId, {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status === 'success') {
                            this.$toast.success(this.$t('message.successes.forwarderCostUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.forwarderCostNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.forwarderCostNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.forwarderCostNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        }
    },
    watch: {
        dialog(value) {
            if(value === true){
                if(this.updateMode === true) {
                    this.loading.get = true
                    this.getForwarderCostToUpdateById()
                        .then(() => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetForm()
                }
            } else {
                this.resetForm()
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allProductGroups.length === 0) this.getAllProductGroups()
    }
}
</script>

<style scoped>

</style>